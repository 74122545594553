import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ABI from "../../components/BlockChainProvider/ABI.json";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import moment from "moment";
import { useWallet } from "use-wallet";
import { setAccount } from "../../redux/features/walletData";
import Countdown from "react-countdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
import { getNormalIPFSURL, getReadablePrice } from "../../utils";
import NFTCard from "../../components/NFTCard";
import config from "../../components/BlockChainProvider/config";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
export const useNFTFromRelation = (id) => {
  let data = [];

  const nftData = useSelector((state) => state.nft.data);
  if (nftData.length > 0) {
    const shuffled = shuffle([...nftData])

    for (let item of shuffled) {
      if (Number(item.id) != Number(id)) {
        data.push(item);
        if (data.length == 8) {
          return data;
        }
      }
    }
  }

  return data;
};

export const useNFTFromId = (id) => {

  const nftData = useSelector((state) => state.nft.data);
  if (nftData.length > 0) {
    const nft = nftData.find((f) => {
      if (Number(f.id) === Number(id)) {
        return f;
      }
    });

    if (nft) {
      return nft;
    } else {
      return -1;
    }
  }

  // const nft = useSelector((state) =>  staxte.nft.data)
};

const Item = (props) => {
  const { id } = getUrlVars();
  const {history} = useHistory()
  const relativeNFTs = useNFTFromRelation(id);
  const [isLoading, setLoading] = useState(false);
  const extraData = useSelector((state) => state.extra.data);
  const amIEligible = useSelector((state) => state.wallet.isEligible);

  const getBNBToBusd = (amount) => {
    if (amount && extraData.usdBNBrice) {
      return `$${((amount / 1e18) * extraData.usdBNBrice).toFixed(2)}`;
    }
  };

  const nft = useNFTFromId(id);
  const wallet = useWallet();
  const isSaleEnabled = useSelector((state) => state.contract.isSaleEnabled);

  const dispatch = useDispatch();
  useEffect(() => {
    if (wallet.account) {
      dispatch(setAccount(wallet.account));
    }
  }, [wallet.account]);

  useEffect(() => {
    if (nft == -1) {
      //show 404
      goTo404();
    }

    console.log("goTo404", nft);
  }, [nft]);

  const goTo404 = () => {
    history.push("/404");
  };

  const getShortWalletAddress = (address) => {
    if (address) {
      return (
        address.substring(0, 4) + "..." + address.substring(address.length - 4)
      );
    }
  };

  function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  const handleBuy = async () => {
    const web3 = new Web3(Web3.givenProvider);
    const _contract = new web3.eth.Contract(ABI, config.CONTRACT_ADDRESS);
    setLoading(true);

    try {
      await _contract.methods
        .buy(nft.id)
        .send({ from: wallet.account, value: nft.price });
    } catch (err) {
    }
    setLoading(false);
  };

 

  const renderMainButton = () => {
    let text = "";
    let className = "btn-main btn-lg";
    let onClick = null;

    if(nft.isSold){
      if(nft.owner && wallet.account&& nft.owner.toLowerCase() === wallet.account.toLowerCase()){
          text = "Owned By You";
      }else{
          text = "Sold Out";
      }
    }else if(isSaleEnabled){
      if(amIEligible){
          text = "Buy Now";
          onClick = handleBuy;
      }else{
          text = "You are not Eligible";
          className = "btn-main btn-light";
      }
    }else{
      text = "Buy Soon"
    }
    if (!wallet.account) {
      text = "Connect Wallet";
      onClick = wallet.connect;
    }

    return (
      <a
        className={`${className} clickable`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {isLoading ? (
          <div
            className="spinner-border text-light"
            role="status"
            style={{
              verticalAlign: "top",
            }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : null}
        {text}
      </a>
    );
  };

  const renderEligiblity = () => {
    if (amIEligible) {
      return null;
    }
    return (
      <div>
        You need to hold atleast <strong>1000 KITESYNC</strong> or Stake{" "}
        <strong>1000 KiteSync</strong> into the{" "}
        <a target="_blank" href="https://kitesync.app/">
          KiteSync Vaults
        </a>{" "}
        in order to become eligible
      </div>
    );
  };
  const renderBidsCards = () => {
    let items = [];

    for (let bid of nft.bids) {
      items.push(
        <a
          href={"http://etherscan.io/tx/" + bid.txnHash}
          target="_blank"
          className="asset__action asset__action hoverable"
        >
          {/* <img src={"/icon/defaultavatar.png"} alt="" /> */}

          <p>
            <b>
              <a
                href={"https://etherscan.io/address/" + bid.walletAddress}
                target="_blank"
              >
                {getShortWalletAddress(bid.walletAddress)}
              </a>
            </b>{" "}
            Placed bid for <br /> <b> {Web3.utils.fromWei(bid.price)} ETH on</b>{" "}
            {moment(bid.createdAt).format("YYYY-MM-DD hh:mm:ss A")}
          </p>
        </a>
      );
    }

    return items;
  };

  return (
    <section
      aria-label="section"
      className=" sm-mt-0"
      style={{ backgroundSize: "cover" }}
    >
      {nft ? (
        <div className="container" style={{ backgroundSize: "cover" }}>
          <div className="row" style={{ backgroundSize: "cover" }}>
            <div
              className="col-md-6 text-center"
              style={{ backgroundSize: "cover" }}
            >
              <img
                src={getNormalIPFSURL(nft.image)}
                className="img-fluid img-rounded mb-sm-30"
                alt=""
              />
            </div>
            <div className="col-md-6" style={{ backgroundSize: "cover" }}>
              <div className="item_info" style={{ backgroundSize: "cover" }}>
                <h2>{nft.name}</h2>
                <div
                  className="item_info_counts"
                  style={{ backgroundSize: "cover" }}
                >
                  {/* <div
                  className="item_info_type"
                  style={{ backgroundSize: "cover" }}
                >
                  <i className="fa fa-image" />
                  Art
                </div> */}
                </div>
                <p>{nft.description}</p>
                <h6>Creator</h6>
                <div
                  className="item_author"
                  style={{ backgroundSize: "cover" }}
                >
                  <div
                    className="author_list_pp"
                    style={{ backgroundSize: "cover" }}
                  >
                    <a href="03_grey-author.html">
                      <img
                        className="lazy"
                        src={getNormalIPFSURL(nft.artist.avatar)}
                        alt=""
                      />
                      <i className="fa fa-check" />
                    </a>
                  </div>
                  <div
                    className="author_list_info"
                    style={{ backgroundSize: "cover" }}
                  >
                    <a href="03_grey-author.html">{nft.artist.name}</a>
                  </div>
                </div>

                <br />

                <div
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    background: "#3e3f6e6e",
                    width: "auto",
                    display: "inline-block",
                    borderRadius: 8,
                  }}
                >
                  <h6 style={{ marginTop: 10 }}>Price</h6>
                  <h3 style={{ marginTop: 10, fontWeight: "bold" }}>
                    {" "}
                    {getReadablePrice(nft.price)} BNB |
                    <span> {getBNBToBusd(nft.price)}</span>
                  </h3>

                  {renderMainButton()}

                  <br />
                </div>

                <div
                  className="spacer-40"
                  style={{ backgroundSize: "cover" }}
                />

                <div
                  className="de_tab tab_simple"
                  style={{ backgroundSize: "cover" }}
                >
                  <div
                    className="spacer-10"
                    style={{ backgroundSize: "cover" }}
                  />
                </div>
              </div>

              <div>{renderEligiblity()}</div>
            </div>
          </div>

          <div className="row" style={{ backgroundSize: "cover" }}>
            <div
              className="spacer-single"
              style={{ backgroundSize: "cover" }}
            />
            {relativeNFTs.length > 0 ? (
              <h2 className="style-2">Related Items</h2>
            ) : null}
            {relativeNFTs.map((item) => {
              return (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-xs-12"
                  style={{ backgroundSize: "cover" }}
                >
                  <NFTCard {...item} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default Item;
