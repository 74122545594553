import { useEffect, useState } from "react";
import Web3 from "web3";
import rawData from "../../config/nfts";
import { setIsEligible } from "../../redux/features/walletData";
import apolloClient from "../../apollo";
import { useSelector, useDispatch } from "react-redux";
import {
  setNFTData as nftStateUpdate,
  setNFTData,
} from "../../redux/features/nftData";
import {
  setContractStatus,
  setNFTContract as updateNFTContract,
} from "../../redux/features/contractData";
import axios from "axios";
import ABI from "./ABI.json";
import config from "./config";
import { Multicall } from "ethereum-multicall";
import BigNumber from "bignumber.js";
import { setExtraData } from "../../redux/features/extraData";
import { useWallet } from "use-wallet";

const BlockChainProvider = () => {
  const [web3, setWeb3] = useState();
  const extraData = useSelector((state) => state.extra.data);
  const [nftData, setNftData] = useState([...rawData]);
  const [nftContract, setNftContract] = useState();

  const dispatch = useDispatch();
  const { account,connect } = useWallet();

  const [bnbUSDPrice, setBNBUSDPrice] = useState();

  useEffect(() => {
    if (Web3.givenProvider) {
      const _web3 = new Web3(Web3.givenProvider);
      setWeb3(_web3);
    }
  }, [Web3.givenProvider]);

  useEffect(() => {
    (async () => {
      let usdBNBrice = await getUSDPrice();
      dispatch(setExtraData({ ...extraData, usdBNBrice }));
      setBNBUSDPrice(usdBNBrice);
    })();
    // loadContractData();
    loadNFTs();
  }, []);

  useWallet(() => {
    connect();
    initContract();
  }, []);

  useEffect(() => {
    loadNFTs();
    if (account && nftContract) {
      loadMyData(account);
    }

    initContract();
  }, [account]);

  const initContract = async () => {
    let _web3 = web3;
    if (!_web3) {
      _web3 = new Web3(new Web3.providers.HttpProvider(config.RPC));
    }

    let _contract = new _web3.eth.Contract(ABI, config.CONTRACT_ADDRESS);
    setNftContract(_contract);
    // dispatch(updateNFTContract(_contract));

    const isSaleEnabled = await _contract.methods.saleIsActive().call();

    dispatch(setContractStatus({ isSaleEnabled }));
  };

  useEffect(() => {
    localStorage.setItem("bnbPrice", bnbUSDPrice);
  }, [bnbUSDPrice]);

  const getUSDPrice = async () => {
    let url =
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";
    let rsp = await axios.get(url);
    try {
      return rsp.data.binancecoin.usd;
    } catch {}
  };

  const loadContractData = async (wallet) => {
    let _web3 = web3;
    if (!_web3) {
      _web3 = new Web3(new Web3.providers.HttpProvider(config.RPC));
    }

    await getAllCollections(_web3, wallet);
  };

  const getNFTById = (id) => {
    const item = nftData.filter(function (item) {
      return item.id === id;
    });
    return item;
  };

  const loadNFTs = async () => {
    const resp = await apolloClient.getAllNFTs();

    try {
      const rawData = resp.data.nfts;
      const finalData = [];

      for (let raw of rawData) {
        const nft = getNFTById(parseInt(raw.id, 16));
        
        if (nft.length>0) {
          finalData.push({
            ...nft[0],
            isSold: raw.isSold,
          });
        }
      }

      setNftData(finalData);
    } catch (err) {

      console.log("loadNFTsErr",err)
    }
  };

  const loadMyData = async (wallet) => {
    if (wallet && nftContract) {
      const isEligible = await nftContract.methods.isEligible(wallet).call();
      dispatch(setIsEligible(isEligible));
    }
  };

  const getAllCollections = async (_web3, _wallet) => {
    // console.log("getAllCollections",_wallet)
    // let allCalls = [];
    // for (const artist of artists) {
    //   let payload = {
    //     reference: artist.contractAddress,
    //     contractAddress: artist.contractAddress,
    //     abi: ABI,
    //   };
    //   let calls = [];
    //   calls.push({
    //     reference: "artistURL",
    //     methodName: "artistURL",
    //     methodParameters: [],
    //   });
    //   calls.push({
    //     reference: "maxNfts",
    //     methodName: "MAX_NFTs",
    //     methodParameters: [],
    //   });
    //   calls.push({
    //     reference: "isSaleActive",
    //     methodName: "saleIsActive",
    //     methodParameters: [],
    //   });
    //   calls.push({
    //     reference: "totalSaleAmount",
    //     methodName: "totalSaleAmount",
    //     methodParameters: [],
    //   });
    //   calls.push({
    //     reference: "totalSupply",
    //     methodName: "totalSupply",
    //     methodParameters: [],
    //   });
    //   if(_wallet){
    //     calls.push({
    //       reference: "isEligible",
    //       methodName: "isEligible",
    //       methodParameters: [_wallet],
    //     });
    //   }
    //   console.log("sdsadsadsadsadasdasdss",_wallet,calls)
    //   payload.calls = calls;
    //   allCalls.push(payload);
    // }
    // let collections = await makeMultiCall(allCalls, true);
    // let allNFTs = [];
    // for (let collection of collections) {
    //   let nfts = await getNFTsByCollection(collection);
    //   console.log("makeeeCallsisEligible",nfts)
    //   allNFTs = [...allNFTs, ...nfts];
    // }
    // setNftData(allNFTs);
  };

  const getNFTsByCollection = async (collection) => {
    const totalSupply = collection.values.totalSupply;

    // const allCalls = {}
    let payload = {
      reference: collection.key,
      contractAddress: collection.key,
      abi: ABI,
      calls: [],
    };
    for (let i = 0; i < totalSupply; i++) {
      let calls = [];

      const context = {
        nftId: i,
        // artistURI:collection.values.artistURL,
        // isSaleActive:collection.values.isSaleActive,
        contract: collection.key,
        collection: { ...collection.values, contract: collection.key },
      };

      calls.push({
        reference: "getNFTInfo",
        methodName: "getNFTInfo",
        methodParameters: [i],
        context,
      });

      payload.calls = [...payload.calls, ...calls];
    }

    let multiCallResults = await basicMultiCall([payload]);
    const multicallNFTs = Object.entries(multiCallResults.results)[0][1]
      .callsReturnContext;

    let rawNFTs = [];
    for (let result of multicallNFTs) {

      let item = {
        nftId: result.methodParameters[0],

        contract: Object.entries(multiCallResults.results)[0][0],
        properties: {
          owner: result.returnValues[0],
          isSold: result.returnValues[1],
          soldAt: new BigNumber(result.returnValues[2].hex).toNumber(),
          createAt: new BigNumber(result.returnValues[3].hex).toNumber(),
          price: new BigNumber(result.returnValues[4].hex).toNumber(),
          tokenURI: result.returnValues[5],
        },
        collection,
      };

      rawNFTs.push(item);
    }

    const finalNFT = [];
    for (let rawNFT of rawNFTs) {
      let metaURI = getNormalIPFSURL(rawNFT.properties.tokenURI);
      let data = await axios.get(metaURI);
      let item = {
        ...rawNFT,
        metaData: data.data,
      };
      let worthInBUSD = bnbUSDPrice * (item.properties.prices / 1e18);
      if (!isNaN(worthInBUSD)) {
        item.properties.usdPrice = worthInBUSD.toFixed(2);
      }
      finalNFT.push(item);
    }


    return finalNFT;
  };

  const getNormalIPFSURL = (url) => {
    return url.replace("ipfs://", config.IPFS_URL);
  };

  const basicMultiCall = async (calls) => {
    const multicall = new Multicall({
      web3Instance: new Web3(config.RPC),
      tryAggregate: false,
      multicallCustomContractAddress:
        "0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D",
    });

    let results = await multicall.call(calls);
    return results;
  };

  const makeMultiCall = async (calls, isCollection) => {
    let results = await basicMultiCall(calls);

    const finalData = [];
    for (let result of Object.entries(results.results)) {
      let key = result[0];
      let rawValues = result[1].callsReturnContext;
      let values = {};
      let context = {};
      let index = 0;

      for (let rawValue of rawValues) {
        if (!isCollection) {
        }
        let subKey = rawValue.reference;
        let subValues = [];
        for (let rawSub of rawValue.returnValues) {
          if (rawSub.type) {
            if (rawSub.type === "BigNumber") {
              subValues.push(new BigNumber(rawSub.hex).toNumber());
            }
          } else {
            subValues.push(rawSub);
          }
        }
        index = index + 1;
        if (
          result[1].originalContractCallContext.calls[index] &&
          result[1].originalContractCallContext.calls[index].context
        ) {
          context = result[1].originalContractCallContext.calls[index].context;
        }
        values[subKey] = subValues;
        if (subValues.length == 0) {
          values[subKey] = "";
        } else if (subValues.length > 1) {
          values[subKey] = subValues;
        } else {
          values[subKey] = subValues[0];
        }
      }

      finalData.push({
        key,
        values,
        context,
      });
    }

    return finalData;
  };

  useEffect(() => {
    if (nftData) {
      dispatch(nftStateUpdate(nftData));
    }
  }, [nftData]);

  return <></>;
};

export default BlockChainProvider;
