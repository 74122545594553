export default [
  {
    id: 0,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/1.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 1,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/1.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 2,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/1.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 3,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/1.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 4,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/1.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 5,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/2.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 6,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/2.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 7,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/2.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 8,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/2.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 9,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/2.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 10,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/3.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 11,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/3.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 12,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/3.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 13,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/3.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 14,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/3.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 15,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/4.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 16,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/4.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 17,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/4.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 18,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/4.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 19,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/4.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 20,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/5.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 21,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/5.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 22,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/5.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 23,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/5.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 24,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/5.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 25,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/6.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 26,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/6.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 27,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/6.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 28,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/6.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 29,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/6.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 30,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/7.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 31,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/7.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 32,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/7.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 33,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/7.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 34,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/7.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 35,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/8.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 36,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/8.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 37,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/8.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 38,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/8.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 39,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/8.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 40,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/9.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 41,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/9.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 42,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/9.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 43,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/9.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
  {
    id: 44,
    name: "A dream world",
    description:
      "This is the first official NFT collection by the team of Kite Sync, these NFTs depicts a beautiful world. These are special NFTs which can be staked and user can earn great rewards by staking these NFTs.",
    image: "ipfs://QmXisHx1fXSaoLSvnUkUdPYhHs5tYXfjXKJGrCu51KAgjo/9.jpg",
    attributes: [],
    price: 1000000000000000000,
    artist: {
      name: "Shikadai",
      avatar: "ipfs://Qma61TQAksGv2qy9BLZSJT2DtN1EQHNqE7sN1uh25DD5sm",
      social: {
        instagram:
          "https://www.instagram.com/shikadai011/?utm_medium=copy_link",
      },
    },
  },
];
