import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import NFTCard from "../../components/NFTCard";
import Artist from "./components/Artists";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Lottie from "react-lottie";
import comingSoonLottie from "../../assets/lotties/comingSoon.json";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
const Home = () => {
  const [nftCards, setNFTCards] = useState([]);
  const nftData = useSelector((state) => state.nft.data);

  const initData = async () => {
    let cards = [];
    let items = [];
    if (nftData) {

      items = shuffle([...nftData]);
    }

    if (items) {
      for (let i = 0; i < items.length; i++) {
        cards.push(renderNFTCard(items[i]));
      }
      setNFTCards(cards);
    }
  };

  useEffect(() => {
    initData();
  }, [nftData]);

  const renderNFTCard = (props) => {
    return (
      <div className=" item" key={Math.random()}>
        <NFTCard {...props} />
      </div>
    );
  };

  return (
    <div className="no-bottom no-top">
      <div id="top" />
      <section
        id="section-hero"
        aria-label="section"
        className="pt20 pb20 vh-100"
        data-bgimage="url(images/background/8.jpg) bottom"
      >
        <div className="v-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="spacer-single" />
                <h6 className="wow fadeInUp" data-wow-delay=".5s">
                  <span className="text-uppercase id-color-2">
                    KiteSync NFTs
                  </span>
                </h6>
                <div className="spacer-10" />
                <h1 className="wow  text-white	" data-wow-delay=".75s">
                  Create, sell and collect digital items.
                </h1>
                <p className="wow fadeInUp lead" data-wow-delay="1s">
                  Unit of data stored on a digital ledger, called a blockchain,
                  that certifies a digital asset to be unique and therefore not
                  interchangeable
                </p>
                <div className="spacer-10" />
                <a
                  href="#"
                  className="btn-main wow fadeInUp lead"
                  data-wow-delay="1.25s"
                >
                  Buy Exclusive Arts
                </a>
                <div className="row">
                  <div className="spacer-single" />
                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 col-sm-4 wow fadeInRight mb30"
                      data-wow-delay="1.1s"
                    >
                      <div className="de_count text-left">
                        <h3>
                          <span>100</span>
                        </h3>
                        <h5 className="id-color">NFTs</h5>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-4 wow fadeInRight mb30" data-wow-delay="1.4s">
                    <div className="de_count text-left">
                      <h3><span>27</span>k</h3>
                      <h5 className="id-color">Auctions</h5>
                    </div>
                  </div> */}
                    <div
                      className="col-lg-4 col-md-6 col-sm-4 wow fadeInRight mb30"
                      data-wow-delay="1.7s"
                    >
                      <div className="de_count text-left">
                        <h3>
                          <span>1</span>k
                        </h3>
                        <h5 className="id-color">NFT Artist (Coming Soon)</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 xs-hide">
                <img
                  src="images/misc/women-with-vr.png"
                  className="img-fluid wow fadeInUp"
                  data-wow-delay=".75s"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section begin */}

      <section id="section-collections" className="pt30 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="style-2">New Items</h2>
            </div>

            {/* {nftCards.length >0? <OwlCarousel className="owl-theme " loop margin={10} nav>
              {nftCards}
            </OwlCarousel>:null} */}

            {nftCards.map((item) => {
              return <div className="col-lg-4">{item}</div>;
            })}
          </div>
          <div className="spacer-single" />
        </div>
      </section>

      <section aria-label="section" className="no-bottom">
        <div className="container">
          <div className="col-lg-12">
            <h2 className="style-2">Suported Wallets</h2>
          </div>
          <div className="row ">
            <div className="col-lg-2 col-sm-4 col-6 mb30">
              <a className="box-url style-2">
                <img src="images/wallet/1.png" alt="" className="mb20" />
                <h4>Metamask</h4>
              </a>
            </div>

            <div className="col-lg-2 col-sm-4 col-6 mb30">
              <a className="box-url style-2">
                <img src="images/wallet/3.png" alt="" className="mb20" />
                <h4>Fortmatic</h4>
              </a>
            </div>
            <div className="col-lg-2 col-sm-4 col-6 mb30">
              <a className="box-url style-2">
                <img src="images/wallet/4.png" alt="" className="mb20" />
                <h4>WalletConnect</h4>
              </a>
            </div>
            <div className="col-lg-2 col-sm-4 col-6 mb30">
              <a className="box-url style-2">
                <img src="images/wallet/5.png" alt="" className="mb20" />
                <h4>Coinbase Wallet</h4>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="section-text" className="no-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="style-2">How To Use?</h2>
            </div>
            <div className="col-lg-4 col-md-6 mb-sm-30">
              <div className="feature-box f-boxed style-3">
                <i className="wow fadeInUp bg-color-2 i-boxed icon_wallet" />
                <div className="text">
                  <h4 className="wow fadeInUp">Set up your wallet</h4>
                  <p className="wow fadeInUp" data-wow-delay=".25s">
                 Install Any Big Web3.js based wallet
                  </p>
                </div>
                <i className="wm icon_wallet" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-sm-30">
              <div className="feature-box f-boxed style-3">
                <i className="wow fadeInUp bg-color-2 i-boxed icon_cloud-upload_alt" />
                <div className="text">
                  <h4 className="wow fadeInUp">Select your NFT's</h4>
                  <p className="wow fadeInUp" data-wow-delay=".25s">
                   We have a range of nfts made by great artist and which have potential to explode in price
                  </p>
                </div>
                <i className="wm icon_cloud-upload_alt" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-sm-30">
              <div className="feature-box f-boxed style-3">
                <i className="wow fadeInUp bg-color-2 i-boxed icon_tags_alt" />
                <div className="text">
                  <h4 className="wow fadeInUp">Buy NFT's</h4>
                  <p className="wow fadeInUp" data-wow-delay=".25s">
                   Just buy without paying any extra fee
                  </p>
                </div>
                <i className="wm icon_tags_alt" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
