
import { createSlice } from '@reduxjs/toolkit'

export const walletData = createSlice({
  name: 'wallet',
  initialState: {
    account: "",
    isEligible:false
  },
  reducers: {
    setAccount: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.account= action.payload
    },
    
    setIsEligible: (state,action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.isEligible= action.payload
    },
    
   
  },
})

// Action creators are generated for each case reducer function
export const { setAccount,setIsEligible } = walletData.actions

export default walletData.reducer