
import Lottie from 'react-lottie';
import animationData from "../../assets/lotties/loading.json"

const Loading = (props)=>{


    return <Lottie 
    options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    }}
    height={props.size?props.size:400}
    width={props.size?props.size:400}
  />
}


export default Loading