import { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { useWallet } from 'use-wallet'
import { setAccount } from '../../redux/features/walletData'
import {getShortAddress} from "../../utils"
const Header = ()=>{

  const wallet = useWallet()


  const dispatch = useDispatch()
  useEffect(()=>{

    if(wallet.account){
      dispatch(setAccount(wallet.account))
    }

  },[wallet.account])



  useEffect(()=>{
    wallet.connect()
  },[])



    return    <header className="transparent scroll-dark">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="de-flex sm-pt10">
            <div className="de-flex-col">
              <div className="de-flex-col">
                {/* logo begin */}
                <div id="logo">
                  <a href="/">
                    <img alt="" src=" https://www.kitesync.io/static/media/logo.ef9189f5.png" height={80} />
                  </a>
                </div>
                {/* logo close */}
              </div>
            
            </div>
            <div className="de-flex-col header-col-mid">
              {/* mainmenu begin */}
              <ul id="mainmenu">
                <li>
                  <a href="/">Home<span /></a>
                  
                </li>


                {/* <li>
                  <a href="/artists">Artists<span /></a>
                  
                </li> */}
               {wallet.account? <li>
                  <a href={`/user?id=${wallet.account}`}>My Items<span /></a>
                  
                </li>:null}
                {/* <li>
                  <a href="03_grey-explore.html">Explore<span /></a>
                  <ul>
                    <li><a href="03_grey-explore.html">Explore</a></li>
                    <li><a href="03_grey-explore-2.html">Explore 2</a></li>
                    <li><a href="03_grey-collection.html">Collections</a></li>
                    <li><a href="03_grey-live-auction.html">Live Auction</a></li>
                    <li><a href="03_grey-item-details.html">Item Details</a></li>
                    <li><a href="03_grey-help-center.html">Help Center</a></li>
                  </ul>
                </li>
                <li>
                  <a href="03_grey-author.html">Author<span /></a>
                  <ul>
                    <li><a href="03_grey-author.html">Author</a></li>
                    <li><a href="03_grey-wallet.html">Wallet</a></li>
                    <li><a href="03_grey-create-options.html">Create</a></li>
                    <li><a href="03_grey-login.html">Login</a></li>
                  </ul>
                </li>                                    
                <li>
                  <a href="03_grey-index-3.html#">Stats<span /></a>
                  <ul>
                    <li><a href="03_grey-activity.html">Activity</a></li>
                    <li><a href="03_grey-rankings.html">Rankings</a></li>
                  </ul>
                </li> */}
              </ul>
              {/* mainmenu close */}

              
              {wallet.account?<div className="menu_side_area">
                <a  className="btn-main"><i className="icon_wallet_alt" /><span>{getShortAddress(wallet.account)}</span></a>
                <span id="menu-btn" />
              </div>:<div className="menu_side_area" onClick={()=>{
                wallet.connect()
              }}>
                <a  className="btn-main"><i className="icon_wallet_alt" /><span>Connect Wallet</span></a>
                <span id="menu-btn" />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>


}

export default Header;