const { useSelector } = require("react-redux");
const config = require("../components/BlockChainProvider/config");

const getNormalIPFSURL = (url) => {
  return url.replace("ipfs://", config.IPFS_URL);
};

const getReadablePrice = (price) => {
  return price / 1e18;
};

const getShortAddress = (address) => {
  if(address){
   return  `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
  }else{
    return ""
  }
};



module.exports = {
  getNormalIPFSURL,
  getReadablePrice,
  getShortAddress
};
