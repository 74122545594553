import { configureStore } from '@reduxjs/toolkit'
import nftReducer  from "./features/nftData"
import walletData from "./features/walletData"

import contractData from "./features/contractData"
import artistData from "./features/artistData"
import extraData from './features/extraData'

export default configureStore({
  reducer: {
    nft:nftReducer,
    wallet:walletData,
    contract:contractData,
    artist:artistData,
    extra:extraData
  }
  ,
})