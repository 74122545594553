import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
import { Provider } from 'react-redux'
import {  UseWalletProvider } from 'use-wallet'
import config from "./components/BlockChainProvider/config";



ReactDOM.render(
  <UseWalletProvider
  chainId={config.CHAIN_ID} >      

  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  
  </Provider>
   </UseWalletProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
