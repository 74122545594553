import { useEffect,useState } from "react"
import { useSelector, useDispatch } from 'react-redux'

import NFTCard from "../../components/NFTCard"


const Home = ()=>{

    const [nftCards,setNFTCards] = useState([])
    const nftData = useSelector((state) => state.nft.data)


    const initData = async ()=>{

        let cards = []
        for(let item of nftData){
            cards.push(renderNFTCard(item));
        }

        setNFTCards(cards)
    }

    useEffect(()=>{
        initData()
    },[nftData])



    const renderNFTCard = (props)=>{

        return  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
        <NFTCard {...props}/>
        </div>
    }


    



    return  <main className="main">
    <div className="container">
      <div className="row row--grid">
      
        {/* title */}
        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>My NFTS</h1>
          </div>
        </div>
        {/* end title */}
        {/* filter */}
        <div className="col-12">
          <div className="main__filter">
            <form action="#" className="main__filter-search">
              <input type="text" placeholder="Search..." />
              <button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" /></svg></button>
            </form>
            <div className="main__filter-wrap">
            <span className="paginator__pages">Total {nftCards.length} Items</span>
           
            </div>
        
        
          </div>
        </div>
        {/* end filter */}
      </div>
      <div className="row row--grid">
    
       {nftCards}
     
      </div>
     
    </div>
  </main>

}

export default Home