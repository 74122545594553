

const Footer = ()=>{

    return  <footer>
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-1">
          {/* <div className="widget">
            <h5>Marketplace</h5>
            <ul>
              <li><a href="03_grey-index-3.html#">All NFTs</a></li>
              <li><a href="03_grey-index-3.html#">Art</a></li>
              <li><a href="03_grey-index-3.html#">Music</a></li>
              <li><a href="03_grey-index-3.html#">Domain Names</a></li>
              <li><a href="03_grey-index-3.html#">Virtual World</a></li>
              <li><a href="03_grey-index-3.html#">Collectibles</a></li>
            </ul>
          </div> */}
        </div>
        {/* <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
            <h5>Resources</h5>
            <ul>
              <li><a href="03_grey-index-3.html#">Help Center</a></li>
              <li><a href="03_grey-index-3.html#">Partners</a></li>
              <li><a href="03_grey-index-3.html#">Suggestions</a></li>
              <li><a href="03_grey-index-3.html#">Discord</a></li>
              <li><a href="03_grey-index-3.html#">Docs</a></li>
              <li><a href="03_grey-index-3.html#">Newsletter</a></li>
            </ul>
          </div>
        </div> */}
        <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
          
           <img src="https://www.kitesync.io/static/media/logo.ef9189f5.png" height={100}/>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
            <h5>Newsletter</h5>
            <p>Signup for our newsletter to get the latest news in your inbox.</p>
            <form action="https://gigaland.io/blank.php" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
              <div className="col text-center">
                <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> <a href="03_grey-index-3.html#" id="btn-subscribe"><i className="arrow_right bg-color-secondary" /></a>
                <div className="clearfix" />
              </div>
            </form>
            <div className="spacer-10" />
            <small>Your email is safe with us. We don't spam.</small>
          </div>
        </div>
      </div>
    </div>
    <div className="subfooter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <a href="03_grey-index.html">
                  <span className="copy">© Copyright 2021 - KiteSync NFTs</span>
                </a>
              </div>
              <div className="de-flex-col">
                <div className="social-icons">
                  {/* <a href="03_grey-index-3.html#"><i className="fa fa-facebook fa-lg" /></a> */}
                  <a target="_blank" href="https://twitter.com/kite_sync"><i className="fa fa-twitter fa-lg" /></a>
                  <a target="_blank" href="https://t.me/kitesync"><i className="fa fa-telegram fa-lg" /></a>
                  {/* <a href="03_grey-index-3.html#"><i className="fa fa-pinterest fa-lg" /></a>
                  <a href="03_grey-index-3.html#"><i className="fa fa-rss fa-lg" /></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer;
