import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import moment from "moment";
import { useWallet } from "use-wallet";
import { setAccount } from "../../redux/features/walletData";
import NFTCard from "../../components/NFTCard";
import Lottie from "react-lottie";
import comingSoonLottie from "../../assets/lotties/comingSoon.json";
import rawData from "../../config/nfts";
import apolloClient from "../../apollo";

export const useArtistFromId = (artistId) => {
  const artistData = useSelector((state) => state.artist.data);


  if (artistData.length > 0) {
    const artist = artistData.find((f) => f.id === Number(artistId));

    if (artist) {
      return artist;
    } else {
      return -1;
    }
  }
};

const ArtistPage = (props) => {
  let artistId = getParam("id");

  const wallet = useWallet();
  const [myNfts, setMyNfts] = useState([]);

  useEffect(() => {
    getUserNFTs(artistId);
  }, [artistId]);

  const getNFTById = (id) => {
    const item = rawData.filter(function (item) {
      return item.id === id;
    });
    return item;
  };

  const getUserNFTs = async (address) => {
    const data = [];
    const myData = await apolloClient.getAllNFTsByAddress(address);
    try {
      const myDataResp = myData.data.nfts;
      for (let item of myDataResp) {
        const nft = getNFTById(parseInt(item.id, 16));
        if (nft.length > 0) {
          data.push(nft[0]);
        }
      }

      setMyNfts(data);
    } catch (err) {
      console.log("errrr",err)
    }
  };

  

  const goTo404 = () => {
    // history.push("/404");
  };

  function getParam(param) {
    return new URLSearchParams(window.location.search).get(param);
  }

  return (
    <section aria-label="section" style={{ backgroundSize: "cover" }}>
      <div className="container" style={{ backgroundSize: "cover",height:myNfts.length ===0?"100vh" :"100%"}}>
        <div className="row" style={{ backgroundSize: "cover" }}>
          {/* <div className="col-md-12" style={{backgroundSize: 'cover'}}>
          <div className="d_profile de-flex" style={{backgroundSize: 'cover'}}>
            <div className="de-flex-col" style={{backgroundSize: 'cover'}}>
              <div className="profile_avatar" style={{backgroundSize: 'cover'}}>
                <img src="images/author_single/author_thumbnail.jpg" alt="" />
                <i className="fa fa-check" />
                <div className="profile_name" style={{backgroundSize: 'cover'}}>
                  <h4>
                    Monica Lucas                                                
                    <span className="profile_username ">Hi i am test</span>
                    <span id="wallet" className="profile_wallet">DdzFFzCqrhshMSxb9oW3mRo4MJrQkusV3fGFSTwaiu4wPBqMryA9DYVJCkW9n7twCffG5f5wX2sSkoDXGiZB1HPa7K7f865Kk4LqnrME</span>
                    <button id="btn_copy" title="Copy Text">Copy</button>



                   
                  </h4>
                  
                </div>
              </div>
            </div>
            <div className="profile_follow de-flex" style={{backgroundSize: 'cover'}}>
              
              
            <div className="de-flex-col">
                <div className="social-icons">
                  <a href="03_grey-index-3.html#"><i className="fa fa-facebook fa-lg" /></a>
                  <a href="03_grey-index-3.html#"><i className="fa fa-twitter fa-lg" /></a>
                  <a href="03_grey-index-3.html#"><i className="fa fa-linkedin fa-lg" /></a>
                  <a href="03_grey-index-3.html#"><i className="fa fa-pinterest fa-lg" /></a>
                  <a href="03_grey-index-3.html#"><i className="fa fa-rss fa-lg" /></a>
                </div>
              </div>
              
            
            </div>
          </div>
        </div> */}
          <div className="col-md-12" style={{ backgroundSize: "cover" }}>
            <div
              className="de_tab tab_simple"
              style={{ backgroundSize: "cover" }}
            >
              <h3> Your Items</h3>
              {/* <ul className="de_nav">
                <li className="active">
                  <span>Your Items </span>
                </li>
                
              </ul> */}
              <div
                style={{ backgroundSize: "cover",  }}
              >
                {!wallet.account ? "Connect Wallet" : null}

                <div className="row">
                  {myNfts.map((nft) => {
                    return (
                      <div
                      className="col-lg-4 col-md-6 col-sm-6 col-xs-12"
                      >
                        <NFTCard {...nft}  hideButton/>
                      </div>
                    );
                  })}

                  {myNfts.length == 0?<h3 style={{fontSize:40,textAlign:"center",marginTop:10}}>No Items Found</h3>:null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArtistPage;
