import { APOLLO_CLIENT } from "../config";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql
  } from "@apollo/client";

const client = new ApolloClient({
  uri: APOLLO_CLIENT,
  cache: new InMemoryCache(),
});

const getAllNFTs = () => {
  const QUERY = `
    {

        nfts(where:{isBurnt:false}) {
          id
          owner
          uri
          createdAt
          isSold
        }
      }
      
      `;

  return client.query({
    query: gql`
      ${QUERY}
    `,
  });
};

const getAllNFTsByAddress = (address) => {
    const QUERY = `
    {

        nfts(where:{owner:"${address}"}) {
          id
          owner
          uri
          createdAt
          isSold
        }
      }
      
        
        `;
  
    return client.query({
      query: gql`
        ${QUERY}
      `,
    });
  };

const apolloClient = {
    client,
    getAllNFTs,
    getAllNFTsByAddress
}



export default apolloClient


