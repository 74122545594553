import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Item from "./pages/Item";
import MyProfile from "./pages/MyProfile";

import Artist from "./pages/Artist"
import Footer from "./components/Footer";
import Header from "./components/Header";
import NotFound  from "./pages/404";
import BlockChainProvider from "./components/BlockChainProvider";
import {  useWallet, UseWalletProvider } from 'use-wallet'
import { useEffect } from "react";

function App() {

  
  return (
    <div>
    <Header />

      <Router>
        <Switch>
          

          <Route exact path='/item/' component={Item} />
          <Route exact path='/profile/' component={MyProfile} />

          <Route exact path="/">
            <Home/>
          </Route>


          <Route exact path='/artists/' component={Artist} />
          <Route exact path='/user/' component={Artist} />


          <Route component={NotFound} />
          <Route exact path="/404"  component={NotFound} />

        </Switch>
      </Router>
      <Footer />
      <BlockChainProvider/>
   
</div>
  );
}

export default App;
