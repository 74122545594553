import { useEffect, useState } from "react";
import "./nft.css";
import { getNormalIPFSURL, getReadablePrice } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { useWallet } from "use-wallet";

const NFTCard = (props) => {
  const extraData = useSelector((state) => state.extra.data);
  const isSaleEnabled = useSelector((state) => state.contract.isSaleEnabled);
  const wallet = useWallet();
  const amIEligible = useSelector((state) => state.wallet.isEligible);

  const renderMainButton = () => {
    let text = "";
    let className = "btn-main ";

    if(props.isSold){
      if(props.owner && wallet.account&& props.owner.toLowerCase() === wallet.account.toLowerCase()){
          text = "Owned By You";
      }else{
          text = "Sold Out";
      }
    }else if (isSaleEnabled) {
      if (amIEligible) {
        text = "Buy Now";
      } else {
        text = "You are not Eligible";
      }
    } else {
      text = "Buy Soon";
    }
    return (
      <a className={`${className} clickable`} style={{ marginTop: 10 }}>
        {text}
      </a>
    );
  };
  const getBNBToBusd = (amount) => {
    if (amount && extraData.usdBNBrice) {
      return `$${((amount / 1e18) * extraData.usdBNBrice).toFixed(2)}`;
    }
  };

  const getNFTUrl = () => {
    return `/item&id=${props.id}`;
  };
  // const [bnbPrice,setBNBPrice] = useState()

  return (
    <div className="nft__item style-2">
      <div className="author_list_pp">
        <div>
          <img
            className="lazy"
            src={getNormalIPFSURL(props.artist.avatar)}
            alt=""
          />
          <i className="fa fa-check" />
        </div>
      </div>
      <div className="nft__item_wrap">
        <a href={getNFTUrl()}>
          <img
            src={getNormalIPFSURL(props.image)}
            className="lazy nft__item_preview"
            alt=""
          />
        </a>
      </div>
      <div className="nft__item_info">
        <a href={getNFTUrl()}>
          <h2>
            {props.name} #{props.id}
          </h2>
        </a>

        <div className="nft__item_price">
          {getReadablePrice(props.price)} BNB
          <span> {getBNBToBusd(props.price)}</span>
        </div>
      </div>
      {props.hideButton?null:renderMainButton()}
    </div>
  );
};

export default NFTCard;
